import React from 'react';
import img from '../../Assests/1.webp';
import img1 from '../../Assests/2.webp';
import img2 from '../../Assests/3.webp';
import img3 from '../../Assests/4.webp';
import img4 from '../../Assests/5.webp';
import './Serv.css';
import { FaBars, FaTimes, FaChevronDown } from 'react-icons/fa';
const services = [
  {
    img: img,
    title: "Desert Adventures",
    description: "We provide a diverse range of services, encompassing cargo operations such as customs clearance and cargo movements to services related to the maritime asset itself, including repairs, maintenance spares, surveys, inspections, towage, pilots, crew logistics.",
    link: '/dubai',
  },
  {
    img: img2,
    title: "Attractions & Sports",
    description: "Our primary goal is to expertly handle every facet of crew management, including attracting.",
    link: '/evening-desart',
  },
  {
    img: img1,
    title: "Cruise & Boat Tours",
    description: "Atlasi recognizes the importance of a fully qualified and well-supported crew for.",
    link: '../crewservices',
  },
  {
    img: img3,
    title: "City Tours",
    description: "Atlasi is dedicated to ensuring safe and compliant operations through transparency, being proactive.",
    link: '../shipmanagement',
  },
  {
    img: img4,
    title: "Special Offers",
    description: "Similar to other industries, the marine sector is increasingly relying on technology to enhance efficiency, safety, and environmental sustainability. Our operational model is the result.",
    link: '../technical',
  },
];

function Serv() {
  return (
    <>
      <div className="service-details">
        <h1>Our <span>Experiences</span></h1>
        <p>Come adventure with us… from award-winning desert safaris to world-famous attractions​</p>
      </div>
      <div className='bodys'>
        <div className="containers">
          <div className="cardss grids">
            {services.map((service, index) => (
              <a href={service.link} key={index}>
                <div className="cardsss">
                  <div className="img-boxs">
                    <img src={service.img} alt={service.title} />
                  </div>
                  <div className="card-contents">
                    <div className="card-icon"><FaChevronDown/> </div> 
                    <h1 className="card-headings">{service.title}</h1>
                    <div className="clickme">
                      <a href={service.link}>Click Me...!</a>
                    </div>
                    {/* <p className="card-texts">{service.description} <a href={service.link}>Read More</a></p> */}
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Serv;
