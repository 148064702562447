import React from 'react'
import Hero from '../../Componends/Leading/Hero'
import Serv from '../../Componends/Serv/Serv'
import Formcon from '../../Componends/Form/Formcon'
import Package from '../../Componends/Package/Package'
import Banner from '../../Componends/Banner/Banner'
import Gallerydetails from '../../Componends/Gallery/Gallerydetails'
import City from '../../Componends/City/City'
// import Gallery from '../../Componends/Gallery/Gallery'

function Homepage() {
  return (
    <div>
   <Hero/>
   <Serv/>
   <Formcon/>
   <Package/>
   <Banner/>
   <City/>
   <Gallerydetails/>
    </div>
  )
}

export default Homepage
