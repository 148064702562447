import React from 'react'
import { Slide } from "react-awesome-reveal";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import './About.css'
function About() {
  return (
    <div>
        <div className="aboutcont">
            <div className="about">
                <div className="details">
                  <div className="abdetails">
<h1>About Dubai Desert Safari Trip UAE</h1>
                     <p>We are a passionate team of adventure enthusiasts dedicated to organizing thrilling desert safari experiences for travelers seeking unforgettable moments in the vast and mesmerizing deserts of the world.</p>
                  </div>
                     
                </div>
                <div className="picabout">
                <div className="backpic"></div>
                <Slide direction='right'>
                <div className="back">
                <img src={require('../../Assests/desert.jpeg')} alt="" />
                </div>
                </Slide>
                </div>
            </div>
        </div>
        <div className='vidhead'>
      <div className="head">
        <div className="head-content">
        <h2>IKRAM TRAVELS AND AGAENCY </h2>
        <h1>ABOUT US</h1>
        </div>
        
        <video className='video-bg' autoPlay muted loop>
            <source src={require('../../Assests/1.mp4')}  type='video/mp4'/>
        </video>
      </div>
      </div>

      <div className="bannercont">
        <div className="banner">
            {/* <img src={require('../../Assests/assets/desert.jpg')} alt="" /> */}
            <div className="bannercontent">
              <div className="bcontent">
                <div className="bicon">
                <IoCheckmarkDoneCircleSharp/>
                </div>
                <div className="bbcon">
                <h1>High Quality Vehicles</h1>
                <h3>We have high quality 4×4 Trucks and Quad Bikes</h3>
                </div>
              
              </div>
              <div className="bcontent">
                <div className="bicon">
                <IoCheckmarkDoneCircleSharp/>
                </div>
                <div className="bbcon">
                <h1>High Quality Vehicles</h1>
                <h3>We have high quality 4×4 Trucks and Quad Bikes</h3>
                </div>
              
              </div>
              <div className="bcontent">
                <div className="bicon">
                <IoCheckmarkDoneCircleSharp/>
                </div>
                <div className="bbcon">
                <h1>High Quality Vehicles</h1>
                <h3>We have high quality 4×4 Trucks and Quad Bikes</h3>
                </div>
              
              </div>
            </div>
        </div>
      </div>


      <div className="vissmisscom">
      <Slide direction='left'>
      <div className="viss">
            <div className="visdetails">
            <h1>Mission</h1>
            <p>At Atlasi Ship Management & Operations LLC, our mission is to provide comprehensive and innovative ship management and repair services that prioritize safety, efficiency, and environmental sustainability. We are committed to delivering exceptional quality and value to our clients through advanced technical expertise, continuous improvement, and a dedicated focus on the well-being of our crew and the protection of our oceans.</p>
            </div>
           
        </div>
                </Slide>
                <Slide direction='right'>
                <div className="miss">
                <div className="misdetails">
            <h1>Vision</h1>
            <p>Our vision is to be the leading ship management and repair company in the Arabian Gulf, renowned for our excellence in service, commitment to environmental stewardship, and dedication to safety. We aim to set industry standards through innovation, sustainability, and unwavering professionalism, ensuring a prosperous and secure future for our clients, employees, and the maritime community.</p>
            </div>
        </div>
                </Slide>
      
      </div>
      
    </div>
  )
}

export default About
