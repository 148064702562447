import React from 'react';
import { FaWhatsapp } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { BiPurchaseTagAlt } from "react-icons/bi";
import { IoIosPricetag, IoIosTime } from "react-icons/io";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { Slide } from "react-awesome-reveal";
import img from '../../Assests/2.webp'
import img1 from '../../Assests/1.webp'
import img2 from '../../Assests/3.webp'
import img3 from '../../Assests/4.webp'
import img4 from '../../Assests/5.webp'
import img5 from '../../Assests/2.webp'
import img6 from '../../Assests/5.webp'
import img7 from '../../Assests/2.webp'
import img8 from '../../Assests/2.webp'

import './Package.css';


const whatsappNumber = '+755317941'; // Replace with your WhatsApp number
  const message = 'Hello! I would like to connect.'; // Optional message

  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
// Sample Data
const packagesData = [
  {
    id: 1,
    title: "Advance Evening Desert Safari",
    img: img,
    description: "Pickup and Dropoff from Hotel or Home",
    time: "2:00 pm – 9:30 pm",
    price: { current: 90, original: 150, currency: "AED" },
    details: [
      "Dune Bash Drive-In 4X4 car",
      "Sandboarding & View Point of Desert",
      "Complimentary Drinks, Coffee & Tea",
      "Camel Riding",
      "Henna Tattoo for Females & Kids",
      "International Buffet Dinner (Vegetarian & Non-Vegetarian with BBQ)",
      "Live Entertainment Shows (Belly Dance, Tanoura Dance, Yalla Dance, Fire Show)"
    ],
    contactOptions: {
      whatsapp: whatsappLink,
      call: "",
      bookOnline: "/booking"
    }
  },
  {
    id: 2,
    title: "Premium Evening Safari with Quad Bike",
    img: img1,
    description: "Pickup by 4×4 Car from Doorstep",
    time: "2:00 pm – 9:30 pm",
    price: { current: 150, original: 250, currency: "AED" },
    details: [
      "Dune Bash Drive-In 4X4 car",
      "Sandboarding & View Point of Desert",
      "Complimentary Drinks, Coffee & Tea",
      "Camel Riding",
      "Henna Tattoo for Females & Kids",
      "International Buffet Dinner (Vegetarian & Non-Vegetarian with BBQ)",
      "Live Entertainment Shows (Belly Dance, Tanoura Dance, Yalla Dance, Fire Show)"
    ],
    contactOptions: {
      whatsapp: whatsappLink,
      call: "",
       bookOnline: "/booking"
    }
  },
  {
    id: 3,
    title: "VIP Desert Safari with Quad Bike",
    img: img2,
    description: "Pickup by 4×4 Car from Doorstep",
    time: "2:00 pm – 9:30 pm",
    price: { current: 230, original: 300, currency: "AED" },
    details: [
      "Dune Bash Drive-In 4X4 car",
      "Sandboarding & View Point of Desert",
      "Complimentary Drinks, Coffee & Tea",
      "Camel Riding",
      "Henna Tattoo for Females & Kids",
      "International Buffet Dinner (Vegetarian & Non-Vegetarian with BBQ)",
      "Live Entertainment Shows (Belly Dance, Tanoura Dance, Yalla Dance, Fire Show)"
    ],
    contactOptions: {
      whatsapp: whatsappLink,
      call: "",
      bookOnline: "/booking"
    }
  },
  {
    id: 4,
    title: "Morning Desert Safari",
    img: img3,
    description: "Pickup by 4×4 Car from Doorstep",
    time: "2:00 pm – 9:30 pm",
    price: { current: 550, original: 700, currency: "AED" },
    details: [
      "Pickup & Drop off from home or hotel",
      "Clean and Air-Conditioned 4×4 Land Cruiser",
      "Dune Bash",
      "Camel Ride",
      "View of the Desert – Photo Print",
      "Sand Boarding",

    ],
    contactOptions: {
      whatsapp: whatsappLink,
      call: "",
    bookOnline: "/booking"
    }
  },
  // {
  //   id: 5,
  //   title: "Private Desert Safari with Buggy",
  //   img: img4,
  //   description: "Pickup by 4×4 Car from Doorstep",
  //   time: "2:00 pm – 9:30 pm",
  //   price: { current: 700, original: 1000, currency: "AED" },
  //   price1: { current: 900, original: 1200, currency: "AED" },
  //   price2: { current: 1200, original: 1600, currency: "AED" },
  //   price3: { current: 1400, original: 1900, currency: "AED" },
  //   details: [
  //     "Dune Bash Drive-In 4X4 car",
  //     "Sandboarding & View Point of Desert",
  //     "Complimentary Drinks, Coffee & Tea",
  //     "Camel Riding",
  //     "Henna Tattoo for Females & Kids",
  //     "International Buffet Dinner (Vegetarian & Non-Vegetarian with BBQ)",
  //     "Live Entertainment Shows (Belly Dance, Tanoura Dance, Yalla Dance, Fire Show)"
  //   ],
  //   contactOptions: {
  //     whatsapp: "",
  //     call: "",
  //     bookOnline: ""
  //   }
  // },
  {
    id: 6,
    title: "Private Desert Safari",
    img: img5,
    description: "Min. 6 Persons",
    time: "2:00 pm – 9:30 pm",
    price: { current:550, original: 600, currency: "AED" },
    details: [
      "Dune Bash Drive-In 4X4 car",
      "Sandboarding & View Point of Desert",
      "Complimentary Drinks, Coffee & Tea",
      "Camel Riding",
      "Henna Tattoo for Females & Kids",
      "International Buffet Dinner (Vegetarian & Non-Vegetarian with BBQ)",
      "Live Entertainment Shows (Belly Dance, Tanoura Dance, Yalla Dance, Fire Show)"
    ],
    contactOptions: {
      whatsapp: whatsappLink,
      call: "",
       bookOnline: "/booking"
    }
  },
  {
    id: 7,
    title: "Basic Safari Package",
    img: img6,
    description: "Pickup Dropoff by Bus from our Centralized locations in Dubai & Sharjah",
    time: "2:00 pm – 9:30 pm",
    price: { current: 55, original: 80, currency: "AED" },
    details: [
      "Dune Bash Drive-In 4X4 car",
      "Sandboarding & View Point of Desert",
      "Complimentary Drinks, Coffee & Tea",
      "Camel Riding",
      "Henna Tattoo for Females & Kids",
      "International Buffet Dinner (Vegetarian & Non-Vegetarian with BBQ)",
      "Live Entertainment Shows (Belly Dance, Tanoura Dance, Yalla Dance, Fire Show)"
    ],
    contactOptions: {
      whatsapp: whatsappLink,
      call: "",
       bookOnline: "/booking"
    }
  },
  {
    id: 8,
    title: "Red Dune Desert Safari",
    img: img7,
    description: "Drive Your Car to Desert Point",
    time: "2:00 pm – 9:30 pm",
    price: { current: 150, original: 250, currency: "AED" },
    details: [
      "Dune Bash Drive-In 4X4 car",
      "Sandboarding & View Point of Desert",
      "Complimentary Drinks, Coffee & Tea",
      "Camel Riding",
      "Henna Tattoo for Females & Kids",
      "International Buffet Dinner (Vegetarian & Non-Vegetarian with BBQ)",
      "Live Entertainment Shows (Belly Dance, Tanoura Dance, Yalla Dance, Fire Show)"
    ],
    contactOptions: {
      whatsapp: whatsappLink,
      call: "",
     bookOnline: "/booking"
    }
  },
  {
    id: 9,
    title: "Advance Evening Desert Safari",
    img: img,
    description: "Pickup and Dropoff from Hotel or Home",
    time: "2:00 pm – 9:30 pm",
    price: { current: 90, original: 150, currency: "AED" },
    details: [
      "Dune Bash Drive-In 4X4 car",
      "Sandboarding & View Point of Desert",
      "Complimentary Drinks, Coffee & Tea",
      "Camel Riding",
      "Henna Tattoo for Females & Kids",
      "International Buffet Dinner (Vegetarian & Non-Vegetarian with BBQ)",
      "Live Entertainment Shows (Belly Dance, Tanoura Dance, Yalla Dance, Fire Show)"
    ],
    contactOptions: {
      whatsapp: whatsappLink,
      call: "",
      bookOnline: "/booking"
    }
  },
 
];

const packagesDatas = [

  {
    id: 5,
    title: "Private Desert Safari with Buggy",
    img: img4,
    description: "Pickup by 4×4 Car from Doorstep",
    time: "2:00 pm – 9:30 pm",
    price: { current: 700, original: 1000, currency: "AED" },
    price1: { current: 900, original: 1200, currency: "AED" },
    price2: { current: 1200, original: 1600, currency: "AED" },
    price3: { current: 1400, original: 1900, currency: "AED" },
    details: [
      "Dune Bash Drive-In 4X4 car",
      "Sandboarding & View Point of Desert",
      "Complimentary Drinks, Coffee & Tea",
      "Camel Riding",
      "Henna Tattoo for Females & Kids",
      "International Buffet Dinner (Vegetarian & Non-Vegetarian with BBQ)",
      "Live Entertainment Shows (Belly Dance, Tanoura Dance, Yalla Dance, Fire Show)"
    ],
    contactOptions: {
      whatsapp: whatsappLink,
      call: "",
       bookOnline: "/booking"
    }
  },

 
];

function Serv() {
  
  return (
    <>
      <Slide direction="left">
        <div className="service-details">
          <div className="sdetails">
            <h1>Desert Safari Trip UAE Deals & Packages</h1>
            <p>Check our desert safari packages. Choose these trending deals! Hurry Up!</p>
          </div>
        </div>
      </Slide>

      <div className='body'>
        <div className="container">
          <div className="cards grid">
            {packagesData.map((pkg) => (
              // <Slide key={pkg.id} direction="right">
                <a className='page' href={`/package${pkg.id}`}>
                  <div className="card" id="evening">
                    <div className="img-box">
                      <img src={pkg.img} alt={pkg.title} />
                      <div className="detailsorder">
                        <div className="order">
                          <h1 className="card-heading">{pkg.title}</h1>
                          <h3>{pkg.description}</h3>
                          <div className="dates">
                            <div className="icons"><IoIosTime /></div> 
                            <div className="datas">{pkg.time}</div>
                          </div> 
                          <div className="dates">
                            <div className="icons"><IoIosPricetag /></div> 
                            <div className="datas">Price: <span className='green'>{pkg.price.current}</span> <span className='red'>{pkg.price.original}</span> {pkg.price.currency} Per Person</div>
                          </div> 
                          
                          <br />
                          <div className="data">
                            
                          {pkg.details.map((detail, index) => (
  <div className="dates" key={index}>
    <div className="icons"><IoCheckmarkDoneCircleSharp /></div>
    <div className="deta">{detail}</div>
  </div>
))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-content">
                      <h1 className="card-heading">BOOK NOW</h1>
                      <h1 className="card-sub">Price:  <span className='green'>{pkg.price.current}</span> <span className='red'>{pkg.price.original}</span>  {pkg.price.currency} /Per Person</h1>
                      <div className="buttonb">
                      <div className="but">
                          <div className="buttonbook">
                          <a href={pkg.contactOptions.whatsapp}> <div className="dates">
                            <div className="icons"><FaWhatsapp /></div> 
                            <div className="datas">WhatsApp</div>
                          </div> 
                          </a>
                          <a href={pkg.contactOptions.call}> <div className="dates">
                            <div className="icons"><IoIosCall /> </div> 
                            <div className="datas"> Call Us</div>
                          </div> 
                          </a>
                          </div>   
                          <div className="buta">
                          <a href={pkg.contactOptions.bookOnline}><div className="dates">
                            <div className="icons"><BiPurchaseTagAlt /> </div> 
                            <div className="datas">  Book Online</div>
                          </div> 
                          </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>

                
              // </Slide>
            ))}
            {packagesDatas.map((pkg) => (
              // <Slide key={pkg.id} direction="right">
                <a className='page' href={`/package${pkg.id}`}>
                  <div className="card" id="evening">
                    <div className="img-box">
                      <img src={pkg.img} alt={pkg.title} />
                      <div className="detailsorder">
                        <div className="order">
                          <h1 className="card-heading">{pkg.title}</h1>
                          <h3>{pkg.description}</h3>
                          <div className="dates">
                            <div className="icons"><IoIosTime /></div> 
                            <div className="datas">{pkg.time}</div>
                          </div> 
                          <div className="dates">
                            <div className="icons"><IoIosPricetag /></div> 
                            <div className="datas">Price: <span className='green'>{pkg.price.current}</span> <span className='red'>{pkg.price.original}</span> {pkg.price.currency} Per Person</div>
                          </div> 
                          <div className="dates">
                            <div className="icons"><IoIosPricetag /></div> 
                            <div className="datas">Price: <span className='green'>{pkg.price1.current}</span> <span className='red'>{pkg.price1.original}</span> {pkg.price1.currency} Per Person</div>
                          </div> 
                          <div className="dates">
                            <div className="icons"><IoIosPricetag /></div> 
                            <div className="datas">Price: <span className='green'>{pkg.price2.current}</span> <span className='red'>{pkg.price2.original}</span> {pkg.price2.currency} Per Person</div>
                          </div> 
                          <div className="dates">
                            <div className="icons"><IoIosPricetag /></div> 
                            <div className="datas">Price: <span className='green'>{pkg.price3.current}</span> <span className='red'>{pkg.price3.original}</span> {pkg.price3.currency} Per Person</div>
                          </div> 
                         
                          <br />
                          <div className="data1">
                          {pkg.details.map((detail, index) => (
  <div className="dates" key={index}>
    <div className="icons"><IoCheckmarkDoneCircleSharp /></div>
    <div className="deta">{detail}</div>
  </div>
))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-content">
                      <h1 className="card-heading">BOOK NOW</h1>
                      <h1 className="card-sub">Price:  <span className='green'>{pkg.price.current}</span> <span className='red'>{pkg.price.original}</span>  {pkg.price.currency} /Per Person</h1>
                      <div className="buttonb">
                        <div className="but">
                          <div className="buttonbook">
                          <a href={pkg.contactOptions.whatsapp}> <div className="dates">
                            <div className="icons"><FaWhatsapp /></div> 
                            <div className="datas">WhatsApp</div>
                          </div> 
                          </a>
                          <a href={pkg.contactOptions.call}> <div className="dates">
                            <div className="icons"><IoIosCall /> </div> 
                            <div className="datas"> Call Us</div>
                          </div> 
                          </a>
                          </div>   
                          <div className="buta">
                          <a href={pkg.contactOptions.bookOnline}><div className="dates">
                            <div className="icons"><BiPurchaseTagAlt /> </div> 
                            <div className="datas">  Book Online</div>
                          </div> 
                          </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>

                
              // </Slide>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Serv;
