// Gallerydetails.js
import React from 'react';
import WSPGallery from './Gallery';
import img1 from '../../Assests/1.webp';
import img2 from '../../Assests/3-600x281.webp';
import img3 from '../../Assests/3.jpg';
import img4 from '../../Assests/4.webp';
import img5 from '../../Assests/6-600x281.webp';
import img6 from '../../Assests/dubai.webp';

const galleryImages = [
    { img: img1 },
    { img: img2 },
    { img: img3 },
    { img: img4 },
    { img: img5 },
    { img: img6 },
    { img: img1 },
    { img: img2 },
    { img: img3 },
    { img: img4 },
    { img: img5 },
    { img: img6 },
];

function Gallerydetails() {
  return (
    <div className="App">
      <br />
      <div className="service-details">
        <h1>Our <span>Gallery</span></h1>
        <p>Come adventure with us… from award-winning desert safaris to world-famous attractions​</p>
      </div>
      <br /><br />

      <WSPGallery galleryImages={galleryImages} />

    
    
    </div>
  );
}

export default Gallerydetails;
