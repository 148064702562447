import React from 'react';
import './Banner.css';
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { FaCarSide } from "react-icons/fa";
import { BiWorld } from "react-icons/bi";
import { MdMiscellaneousServices } from "react-icons/md";

const bannerContents = [
  {
    icon: <FaCarSide />,
    title: "High Quality Vehicles",
    description: "We have high quality 4×4 Trucks and Quad Bikes",
  },
  {
    icon: <BiWorld />,
    title: "Certified",
    description: "We are certified and provide top-notch services to our clients",
  },
  {
    icon: <MdMiscellaneousServices />,
    title: "Best Service",
    description: "We fulfill all your needs and requirements",
  },
];

function Banner() {
  return (
    <div className="bannercont">
      <div className="banner">
        <div className="bannercontent">
          {bannerContents.map((content, index) => (
            <div className="bcontent" key={index}>
              <div className="bicon">
                {content.icon}
              </div>
              <div className="bbcon">
                <h1>{content.title}</h1>
                <h3>{content.description}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Banner;
