import { BrowserRouter,Routes,Route } from "react-router-dom";
import Header from "./Componends/Header/Header";
import Homepage from "./Pages/Homepage/Homepage";
import Footer from "./Componends/Footer/Footer";
import Aboutpage from "./Pages/Homepage/Aboutpage";
import Formcon from "./Componends/Form/Formcon";
import Dubai from "./Componends/Packagesd/Dubai";
import Evening from "./Componends/Packagesd/Evening";
import Morning from "./Componends/Packagesd/Morning";
import Night from "./Componends/Packagesd/Night";
import Packages1 from "./Package/Packages1";
import Package2 from "./Package/Package2";
import Package3 from "./Package/Package3";
import Package4 from "./Package/Package4";
import Package5 from "./Package/Package5";
import Package6 from "./Package/Package6";
import Package7 from "./Package/Package7";
import Package8 from "./Package/Package8";
import Top from "./Componends/Top/Top";

function App() {
  return (
    <div className="App">
     <BrowserRouter>
     <Top/>
    
      <Header/>
      <Routes>
        <Route path="/" element={<Homepage/>}></Route>
        <Route path='/about' element={<Aboutpage/>}></Route>
      <Route path='/booking' element={<Formcon/>}></Route>
      <Route path='/dubai' element={<Dubai/>}></Route>
      {/* <Route path='/contact' element={<Contactus/>}></Route> */}
      <Route path='/evening-desart' element={<Evening/>}></Route>
      <Route path='/morning-desart' element={<Morning/>}></Route>
      <Route path='/night-desart' element={<Night/>}></Route>
      <Route path='/dubai-tour' element={<Dubai/>}></Route>
      <Route path='/package1' element={<Packages1/>}></Route>
      <Route path='/package2' element={<Package2/>}></Route>
      <Route path='/package3' element={<Package3/>}></Route>
      <Route path='/package4' element={<Package4/>}></Route>
      <Route path='/package5' element={<Package5/>}></Route>
       <Route path='/package6' element={<Package6/>}></Route>
       <Route path='/package7' element={<Package7/>}></Route>
       <Route path='/package8' element={<Package8/>}></Route>
      </Routes>
      <Footer/>
     </BrowserRouter>
    </div>
  );
}

export default App;
