import React, { useEffect, useState } from 'react';
import img from '../../Assests/1.webp';
import img1 from '../../Assests/2.webp';
import img2 from '../../Assests/3.webp';
import img3 from '../../Assests/4.webp';
import img4 from '../../Assests/5.webp';
import { FaLongArrowAltRight } from "react-icons/fa";

// import mark from '../../Assests/assets/right.png'


import { FaEnvelope } from 'react-icons/fa';
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import './Hero.css';

function Hero() {
  const [itemActive, setItemActive] = useState(0);
  const items = [img, img1, img2, img3, img4];
  const countItem = items.length;

  useEffect(() => {
    const showSlider = () => {
      const itemsDOM = document.querySelectorAll('.slider .list .item');
      const thumbnailsDOM = document.querySelectorAll('.thumbnail .item');

      itemsDOM.forEach((item, index) => {
        item.classList.remove('active');
        thumbnailsDOM[index].classList.remove('active');
      });

      itemsDOM[itemActive].classList.add('active');
      thumbnailsDOM[itemActive].classList.add('active');
    };

    const refreshInterval = setInterval(() => {
      setItemActive((prev) => (prev + 1) % countItem);
    }, 3000);

    showSlider();

    return () => {
      clearInterval(refreshInterval);
    };
  }, [itemActive, countItem]);

  const handleNext = () => {
    setItemActive((prev) => (prev + 1) % countItem);
  };

  const handlePrev = () => {
    setItemActive((prev) => (prev - 1 + countItem) % countItem);
  };

  const handleThumbnailClick = (index) => {
    setItemActive(index);
  };

  return (
    <div>
      <div className="slider">
        <div className="list">
          {items.map((image, index) => (
            <div key={index} className={`item ${index === itemActive ? 'active' : ''}`}>
              <img src={image} alt="" />
              <div className="content">
                <p>Travels And Tourisum</p>
                <h2>IKRAM TRAVEL</h2>
                <p>
                "Experience the Ultimate Desert Adventure: Discover Desert Safari Trip UAE!"
                </p>
                <p>
                <a href="mailto:Info@a.com" style={{ textDecoration: 'none', color: 'inherit' }}>
                <FaEnvelope size={18} /> {/* Email icon */}
                <span>  Info@ikramtravel.com</span> {/* Optional text */}
            </a>
                </p>
                <p>
                  <br />
                  <div className="conbut">
                  <a href="">CONTACT US <FaLongArrowAltRight /> </a>
                  </div>
                
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="arrows">
          <button id='prev' onClick={handlePrev}><FaArrowLeft /></button>
          <button id='next' onClick={handleNext}><FaArrowRight/></button>
        </div>

        <div className="thumbnail">
          {items.map((image, index) => (
            <div key={index} className={`item ${index === itemActive ? 'active' : ''}`} onClick={() => handleThumbnailClick(index)}>
              <img src={image} alt="" />
              <div className="content">
                
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Hero;
