import React from 'react'
import { FaWhatsapp } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { BiPurchaseTagAlt } from "react-icons/bi";
import img from '../../Assests/1.webp'
import img1 from '../../Assests/3.webp'
import { Slide } from "react-awesome-reveal";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
function Dubai() {
  return (
    <div>
                <div className='vidhead'>
      <div className="head">
        <div className="head-content">
        <h2>IKRAM TRAVELS AND AGAENCY </h2>
        <h1>Dubai City Tour</h1>
        </div>
        
        <video className='video-bg' autoPlay muted loop>
            <source src={require('../../Assests/3.mp4')}  type='video/mp4'/>
        </video>
      </div>
      </div>

      <div className="vissmisscom">
      <Slide direction='left'>
      <div className="viss">
            <div className="visdetails">
            <h1>Dubai City Tour</h1>
            <p>If you’re planning a trip to the UAE, make sure a Dubai city tour is at the top of your list. Situated in the southern part of the Gulf states along the Arabian Sea coast, Dubai boasts a stunning blend of expansive landscapes and breathtaking skyscrapers.</p>
            <p>Dubai is renowned as one of the most beautiful and ultra-modern cities not only in the UAE but globally. Its unique and well-planned infrastructure sets it apart on the world map. The towering buildings are so high that they seem to touch the clouds, creating a picturesque skyline. The perfect fusion of modernity and tradition makes Dubai an essential destination to visit.</p>
            </div>
           
        </div>
                </Slide>
               
      </div>
       <br />
       <br />
      <div className="vissmisscom">
      <Slide direction='left'>
      <div className="viss">
            <div className="visdetails">
            <h1>About the Tour</h1>
            <p>We will arrange transportation to pick you up from your accommodation and provide a guided tour of Dubai, sharing insights about its cultural heritage and development history.</p>
            <p>As a global business hub, Dubai boasts some of the world’s largest shopping malls. We will take you to the city’s most famous malls and shopping centers, including The Dubai Mall, Mall of the Emirates, BurJuman Centre, and Ibn Battuta Mall. These shopping havens feature massive infrastructures and ultra-modern architecture, offering a paradise for brand lovers. Additionally, we can recommend the best luxury hotels to enhance your stay in Dubai, such as Atlantis, one of the top 7-star hotels in the city.</p>
            </div>
           
        </div>
                </Slide>
               
      
      </div>

      <Slide direction="left">
        <div className="service-details">
        <div className="sdetails">
   <h1>Our Package</h1>
   <p>Dubai Desert Safari provides incredible Dubai city tour deals at highly affordable prices. We offer a variety of packages tailored to meet your needs and budget. Our goal in creating these Dubai city tour deals is to accommodate all tourists eager to explore Dubai. Each trip category is thoughtfully designed, showcasing both natural and man-made attractions.</p>
</div>
</div>
        </Slide>

        <div className='body'>
      <div className="container">
        <div className="cards grid">
        <Slide direction="right">
        <div className="card" id="evening">
                <div className="img-box">
                <img src={img} alt="" />
                 <div className="detailsorder">
                  <div className="order">
                  <h1 className="card-heading">Advance Evening Desert Safari</h1>
                  <h3>Pickup and Dropoff from Hotel or Home</h3>
                  <p><IoCheckmarkDoneCircleSharp />2:00 pm – 9:30 pm</p>
                  <p><IoCheckmarkDoneCircleSharp />Price: 90 150 AED / Per Person</p>
                  <p><IoCheckmarkDoneCircleSharp />Dune Bash Drive-In 4X4 car</p>
                  <p><IoCheckmarkDoneCircleSharp />Sandboarding & View Point of Desert</p>
                  <p><IoCheckmarkDoneCircleSharp />Complimentary Drinks, Coffee & Tea</p>
                  <p><IoCheckmarkDoneCircleSharp />Camel Riding</p>
                  <p><IoCheckmarkDoneCircleSharp />Henna Tattoo for Females & Kids</p>
                  <p><IoCheckmarkDoneCircleSharp />International Buffet Dinner (Vegetarian & Non-Vegetarian with BBQ)</p>
                  <p><IoCheckmarkDoneCircleSharp />Live Entertainment Shows (Belly Dance, Tanoura Dance,Yalla Dance, Fire Show)</p>
                  <div className="read">
                  <a href="/package1">Read More</a>
                  </div>
                  
                  </div>
                 </div>
                
                </div>
                <div className="card-content">
            <h1 className="card-heading">BOOK NOW</h1>
            <h1 className="card-sub">Price:  90 <span>150</span> AED /Per Person</h1>
            <div className="buttonb">
              <div className="but">
              <div className="buttonbook">
            <a href=""><FaWhatsapp/>WhatsApp</a>
            <a href=""><IoIosCall/> Call Us</a>
            </div>   
            <div className="buta">
            <a  href=""><BiPurchaseTagAlt/>Book Online</a>
            </div>
          
              </div>
         
            </div>
            
            </div>
            </div>
          </Slide>
         
         
        </div>
      </div>
    </div>

      




  
    </div>
  )
}

export default Dubai
