import React, { useState } from 'react';
import './Faq.css'; // Make sure to import your CSS for styling
import { Slide } from 'react-awesome-reveal';
import { IoIosArrowDropdownCircle } from "react-icons/io";
import {AiOutlineStar} from "react-icons/ai";
function Faq() {
  const [activeIndex, setActiveIndex] = useState(null);

  const togglePanel = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className='faq-con1'>
 <div className="faq-con">
      <button className="accordion" onClick={() => togglePanel(0)}>
       Comments <IoIosArrowDropdownCircle />
      </button>
      <div className="panel" style={{ display: activeIndex === 0 ? 'block' : 'none' }}>
       <p>"John Michel"   <AiOutlineStar/><AiOutlineStar/><AiOutlineStar/></p>
       <p>Lankan Administration Services provided efficient, reliable support, helping streamline our business processes. Highly professional and detail-oriented</p>

       <p>"John Michel"   <AiOutlineStar/><AiOutlineStar/><AiOutlineStar/><AiOutlineStar/></p>
       <p>Lankan Administration Services provided efficient, reliable support, helping streamline our business processes. Highly professional and detail-oriented</p>
      </div>
    </div>

    </div>
   
  );
}

export default Faq;
