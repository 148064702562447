import React, { useState } from 'react'
import './Formcon.css'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

// Form validation schema
const validationSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    email: Yup.string().required('Email is required').email('Email is invalid'),
    checkingDate: Yup.date().required('Checking date is required').min(new Date(), 'Checking date cannot be in the past'),
    packageSelected: Yup.string().required('Please select a package'),
    personCount: Yup.number().required('Person count is required').min(1, 'At least 1 person is required').max(10, 'Cannot exceed 10 persons'),
  });
function Formcon() {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
      });
      const [phone, setPhone] = useState('');
    
      const onSubmit = (data) => {
        console.log({ ...data, phoneNumber: phone });
      };
  return (
    <div>
       <div className="formmaincont">
         <div className="formcont">
            {/* <div className="pic">
                <img src={require('../../Assests/assets/3-600x281.webp')} alt="" />
            </div> */}
            
            <div className="form">
               
            <form onSubmit={handleSubmit(onSubmit)}>
            <h2>Book Your Trip</h2>
      <div>
        <label>Username</label>
        <input type="text" {...register('username')} autoComplete='off'/>
        <p className='p'>{errors.username?.message}</p>
      </div>

      <div>
        <label>Phone Number</label>
        <PhoneInput
         country={'ae'} 
          value={phone}
          onChange={(phone) => setPhone(phone)}
          inputStyle={{
            width:'94%',
            marginLeft:'40px',
            paddingLeft: '40px', // Ensure the input doesn't overlap with the flag
            height: '60px', // Adjust height as needed
            border: 'none',
            borderRadius:'0px',
            border:'1px solid var(--button)',
            
          }}
          buttonStyle={{
            border: 'none',
            width:'80px',
            height:'100%',
            marginRight:'20px',
          }}
           autoComplete="off"
        />
        <p className='p'>{errors.phoneNumber?.message}</p>
      </div>

      <div>
        <label>Email</label>
        <input type="email" {...register('email')} autoComplete="off" />
        <p className='p'>{errors.email?.message}</p>
      </div>

      <div>
        <label>Checking Date</label>
        <input type="date" {...register('checkingDate')} />
        <p className='p'>{errors.checkingDate?.message}</p>
      </div>

      <div>
        <label>Package Selected</label>
        <select {...register('packageSelected')}>
          <option value="">Select package</option>
          <option value="basic">Basic</option>
          <option value="premium">Premium</option>
          <option value="vip">VIP</option>
        </select>
        <p className='p'>{errors.packageSelected?.message}</p>
      </div>

      <div>
        <label>Person Count</label>
        <input type="number" {...register('personCount')}  autoComplete="off" />
        <p className='p'>{errors.personCount?.message}</p>
      </div>
       <div className="buttoncon">
       <button className='button' type="submit">Submit</button>
       </div>
     
    </form>
            </div>
         </div>
      </div>
    </div>
  )
}

export default Formcon
