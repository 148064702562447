import React from 'react'
import { FaFacebookF , FaInstagramSquare } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import './Top.css'
function Top() {
  return (
    <div>
      <div className="topcontaoner">
        <div className="deatils-cont">
          <div className="logo">
            <img src={require('../../Assests/desert.jpeg')} alt="" />
          </div>
          <div className="details">
            <div className="topdetails">
              <div className="contactde">
              <a href="tel:+971504120710">+971504120710</a> || <a href="mailto:sales@ikramtravelagency.com">sales@ikramtravelagency.com</a>
              </div>
               <div className="socialmedi">
               <a href="https://www.instagram.com/ikramtravelagency/profilecard/?igsh=MTV3d3I4ZXM4NWdwbA==" target="_blank" rel="noopener noreferrer">
      <FaInstagramSquare size={36} />
    </a>
               </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Top
