import React from 'react'
import About from '../../Componends/About/About'
// import Card from '../Components/Service/Card'
// import Services from '../Components/Service/Services'

function Aboutpage() {
  return (
    <div>
      <About/>
      {/* <Services/> */}
    </div>
  )
}

export default Aboutpage
