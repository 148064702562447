import React from 'react'
import './City.css'
import { Slide } from "react-awesome-reveal";
function City() {
  return (
    <div>
       <div className="service-details">
        <h1>Our <span>Dubai City Tour</span></h1>
        <p>Come adventure with us… from award-winning desert safaris to world-famous attractions​</p>
      </div>
      <div className="citycont">
      
        <div className="city">
            <div className="ccont">
                <div className="piccon">
                    <img src={require('../../Assests/dubai.webp')} alt="" />
                </div>
                <div className="ccontent">
                  <div className="ccont">
                  <h1>Dubai City Tour</h1>
                  <p>In the Dubai City Tour, get to know Dubai’s history, culture, and modern and unique aspects. Likewise, there is no doubt that Dubai is known for its beautiful scenery and diverse activities around the city.</p>

                  <div className="bookci">
                    <a href="/booking">Booking</a>
                  </div>
                  </div>
                    
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default City
