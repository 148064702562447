// App.js
import React, { useState } from 'react';
import './Header.css';
import { FaBars, FaTimes, FaChevronDown } from 'react-icons/fa';

const App = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('');
  const [dropdowns, setDropdowns] = useState({ about: false, works: false });

  const handleToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setIsNavOpen(false); // Close menu on link click (optional for mobile)
  };

  const toggleDropdown = (dropdown) => {
    setDropdowns({ ...dropdowns, [dropdown]: !dropdowns[dropdown] });
  };

  return (
    <header className="header">
      <a href="#" className="header__logo">Ikram Travel</a>
      <i onClick={handleToggle} className={`header__toggle ${isNavOpen ? 'bx-x' : 'bx-menu'}`} id="header-toggle">
        {isNavOpen ? <FaTimes /> : <FaBars />}
      </i>

      <nav className={`nav ${isNavOpen ? 'show' : ''}`} id="nav-menu">
        <div className="nav__content bd-grid">
          <a href="/" className="nav__perfil">
            <div className="nav__img">
              <img src={require('../../Assests/desert.jpeg')} alt="Profile" />
            </div>
            <div>
              <span className="nav__name">Ikram</span>
              <span className="nav__name">Travel</span>
            </div>
          </a>

          <div className="nav__menu">
            <ul className="nav__list">
              <li className="nav__item">
                <a href="/" onClick={() => handleLinkClick('home')} className={`nav__link ${activeLink === 'home' ? 'active' : ''}`}>Home</a>
              </li>

              <li className="nav__item dropdown">
                <div onClick={() => toggleDropdown('about')} className="nav__link dropdown__link">
                  Packages <FaChevronDown className="dropdown__icon" />
                </div>
                {dropdowns.about && (
                  <ul className="dropdown__menu">
                    <li className="dropdown__item"><a href="/morning-desart" onClick={() => handleLinkClick('about')} className="nav__link">Morning Desert Safari</a></li>
                    <li className="dropdown__item"><a href="/evening-desart" onClick={() => handleLinkClick('location')} className="nav__link">Evening Desert Safari</a></li>
                    <li className="dropdown__item"><a href="/night-desart" onClick={() => handleLinkClick('studies')} className="nav__link">Over Night Desert Safari</a></li>
                  </ul>
                )}
              </li>

              <li className="nav__item">
                <a href="/dubai" onClick={() => handleLinkClick('skills')} className={`nav__link ${activeLink === 'skills' ? 'active' : ''}`}>Dubai City</a>
              </li>

              <li className="nav__item dropdown">
                <div onClick={() => toggleDropdown('works')} className="nav__link dropdown__link">
                  Services <FaChevronDown className="dropdown__icon" />
                </div>
                {dropdowns.works && (
                  <ul className="dropdown__menu">
                    <li className="dropdown__item"><a href="/package1" onClick={() => handleLinkClick('recent-jobs')} className="nav__link">Advance Evening Desert Safari</a></li>
                    <li className="dropdown__item"><a href="/package2" onClick={() => handleLinkClick('better-jobs')} className="nav__link">Premium Evening Safari with Quad Bike</a></li>
                    <li className="dropdown__item"><a href="/package3" onClick={() => handleLinkClick('work-awards')} className="nav__link">VIP Desert Safari with Quad Bike</a></li>
                    <li className="dropdown__item"><a href="/package4" onClick={() => handleLinkClick('Morning Desert Safari')} className="nav__link">Morning Desert Safari</a></li>
                    <li className="dropdown__item"><a href="/package5" onClick={() => handleLinkClick('Private Desert Safari')} className="nav__link">Private Desert Safari</a></li>
                    <li className="dropdown__item"><a href="/package6" onClick={() => handleLinkClick('Basic Safari Package')} className="nav__link">Basic Safari Package</a></li>
                    <li className="dropdown__item"><a href="/package7" onClick={() => handleLinkClick('recent-jobs')} className="nav__link">Red Dune Desert Safari</a></li>
                    <li className="dropdown__item"><a href="/package8" onClick={() => handleLinkClick('Private Desert Safari with Buggy')} className="nav__link">Private Desert Safari with Buggy</a></li>
                    <li className="dropdown__item"><a href="/package9" onClick={() => handleLinkClick('work-awards')} className="nav__link">VIP Desert Safari with Quad Bike</a></li>
                  </ul>
                )}
              </li>

              <li className="nav__item">
                <a href="#" onClick={() => handleLinkClick('contact')} className={`nav__link ${activeLink === 'contact' ? 'active' : ''}`}>Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default App;
