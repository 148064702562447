import React from 'react'
import { FaFacebookF , FaInstagramSquare } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { IoLocation } from "react-icons/io5";
import { MdAttachEmail } from "react-icons/md";
import './Footer.css'
function Footer() {
  return (
    <div>
      <div className="footercont">
        <div className="footerco">
            <div className="footerdetails">
               <img src={require('../../Assests/desert.jpeg')} alt="" />
               <h1>Ikram taravels</h1>
               <p>We are a passionate team of adventure enthusiasts dedicated to organizing thrilling desert safari experiences for travelers seeking unforgettable moments in the vast and mesmerizing deserts of the world.</p>
               
            </div>
            <div className="footercontent">
                <h1>Contact Us</h1>
                <div className="footercontentli">
                    <div className="listf">
                      <div className="ic">
                      <FaPhoneAlt/>
                      </div>
                     <div className="ic1">
                     <a href="tel:+971504120710">+971504120710</a>
                     </div>
                    </div>
                    <div className="listf">
                      <div className="ic">
                      <IoLocation/>
                      </div>
                     <div className="ic1">
                     <a href="">Telephone</a>
                     </div>
                    </div>
                    <div className="listf">
                      <div className="ic">
                      <MdAttachEmail/>
                      </div>
                     <div className="ic1">
                     <a href="mailto:sales@ikramtravelagency.com">sales@ikramtravelagency.com</a>
                     </div>
                    </div>
                 
                </div>
              

                <div className="footersocial">
                <a href="https://www.instagram.com/ikramtravelagency/profilecard/?igsh=MTV3d3I4ZXM4NWdwbA==" target="_blank" rel="noopener noreferrer">
      <FaInstagramSquare size={48} />
    </a>
            </div>
            </div>
            <div className="footerlink">
            <h1>Quick Link</h1>
            <a href="/evening-desart">Evening Desert Safari</a>
            <a href="../crewservices">CREW SERVICES</a>
            <a href="../shipmanagement">SHIP MANAGMENT</a>
            </div>
           
        </div>
        <div className="copy">
            <h2>Aln Innovation Company </h2>
        </div>
      </div>
    </div>
  )
}

export default Footer
