import React, { useState } from 'react';
import './package.css';
import imgNature1 from '../Assests/2.webp';
import { FaWhatsapp } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { BiPurchaseTagAlt } from "react-icons/bi";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import img from '../Assests/gallery-22.webp'
import img1 from '../Assests/gallery-31.webp'
import img2 from '../Assests/gallery-33.webp'
import img3 from '../Assests/gallery-35.webp'
import img4 from '../Assests/gallery-53.webp'
import img5 from '../Assests/gallery-55.webp'
import img6 from '../Assests/gallery-58.webp'
import img7 from '../Assests/gallery-61.webp'
import Faq from './Faq/Faq';


function Packages1() {
  // Initialize with a default image
  const [expandedImg, setExpandedImg] = useState(imgNature1);
  const [imgText, setImgText] = useState('Nature');

  const images = [
    { src: imgNature1, alt: '' },
    { src: img, alt: '' },
    { src: img1, alt: '' },
    { src: img2, alt: '' },
  ];

  const handleClick = (image) => {
    setExpandedImg(image.src);
    setImgText(image.alt);
  };

  return (
    <> 
     <div className="packagecont">
      <div className="packagecon">
        <div className="packpic">
          {/* Expanded image container is always shown */}
          <div className="pacontainer">
            <img className="expandedImg" src={expandedImg} alt={imgText} style={{ width: '100%' }} />
            <div id="imgtext">{imgText}</div>
          </div>

          <div className="row">
            {images.map((image, index) => (
              <div className="column" key={index}>
                <img
                  src={image.src}
                  alt={image.alt}
                  onClick={() => handleClick(image)}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="packdetials">
        <h1 className="card-heading">Advance Evening Desert Safari</h1>
                  <h3>Pickup and Dropoff from Hotel or Home</h3>
                  <p><IoCheckmarkDoneCircleSharp />2:00 pm – 9:30 pm</p>
                  <p><IoCheckmarkDoneCircleSharp />Price: 90 150 AED / Per Person</p>
                  <p><IoCheckmarkDoneCircleSharp />Dune Bash Drive-In 4X4 car</p>
                  <p><IoCheckmarkDoneCircleSharp />Sandboarding & View Point of Desert</p>
                  <p><IoCheckmarkDoneCircleSharp />Complimentary Drinks, Coffee & Tea</p>
                  <p><IoCheckmarkDoneCircleSharp />Camel Riding</p>
                  <p><IoCheckmarkDoneCircleSharp />Henna Tattoo for Females & Kids</p>
                  <p><IoCheckmarkDoneCircleSharp />International Buffet Dinner (Vegetarian & Non-Vegetarian with BBQ)</p>
                  <p><IoCheckmarkDoneCircleSharp />Live Entertainment Shows (Belly Dance, Tanoura Dance,Yalla Dance, Fire Show)</p>   

                  <div className="commentscont">
                    <Faq/>
                  </div>
                  <div className="packbutton">
                  <div className="buttonb">
              <div className="but">
              <div className="buttonbook">
            <a href=""><FaWhatsapp/>WhatsApp</a>
            <a href=""><IoIosCall/> Call Us</a>
            </div>   
            <div className="buta">
            <a  href="/booking"><BiPurchaseTagAlt/>Book Online</a>
            </div>
          
              </div>
         
            </div>
                  </div>
             
        </div>
      </div>
    </div>
    
    
    
 
    </>
   
  );
}

export default Packages1;
